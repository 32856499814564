import React, { useEffect } from "react";
import { Navbar } from "../component/navbar";
import { Footer } from "../component/Footer";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Form, Input, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EMAIL_URL } from "../assets/data/commonValues";

export const Start = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const onFinish = (values) => {
    console.log(values);
    let string = "";

    Object.keys(values).forEach((x) => {
      let a = x.replace(/([A-Z])/g, " $1").trim();

      string += capitalize(a).replace("-", "") + ": " + values[x] + "<br>";
    });
    // string = encodeURIComponent(string);

    // console.log(string, "START");
    // return;
    Axios({
      method: "POST",
      url: EMAIL_URL,
      data: {
        to: "ADMIN",
        subject: "New Application",
        content: string,
      },
    }).then((response) => {
      toast.success("Thank you, Your application has been submitted");
      form.resetFields();
    });
  };

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please check your form, fill in the required field");
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="start-wrapper hidden">
      <ToastContainer />
      <div className="start-header text-center">
        <Navbar />
        <div className="content container">
          <p className="mb-4 font-bold font50">Getting Started</p>
        </div>
      </div>
      <div className="mb-5 container">
        <div className="form-wrapper">
          <div className="form-header font-bold font18 px-4 py-3">
            Please Enter the details below
          </div>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="card">
              <div className="row">
                <div className="col-lg-5 col-sm-12 my-2 ml-auto">
                  <div className="form-group">
                    <label>Date of Referral</label>
                    <Form.Item name="dateOfReferral">
                      <input type="date" className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="my-3 font-bold font20">Client Information:</div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label> First Name </label>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Last Name </label>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Middle Name </label>
                    <Form.Item name="middleName">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label> DOB </label>
                    <Form.Item
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <input type="date" className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Address </label>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <TextArea rows="2" className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Phone </label>
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mb-2">
                  <div className="form-group">
                    <label> Gender </label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4 flex-wrap">
                      <div className="mt-2">
                        <Form.Item
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "check one!",
                            },
                          ]}
                        >
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            Male
                            <input type="radio" name="gender" />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div className="mt-2">
                        <Form.Item
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "check one!",
                            },
                          ]}
                        >
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Female
                            <input type="radio" name="gender" />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div className="mt-2">
                        <Form.Item
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "check one!",
                            },
                          ]}
                        >
                          <label className="radio-container mb-0 font14 font-AftaSans">
                            Non-binary
                            <input type="radio" name="gender" />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mb-2">
                  <div className="form-group">
                    <label> Language </label>
                    <Form.Item name="language">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mb-2">
                  <div className="form-group">
                    <label> Is Interpreter Required? </label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="interpreter">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input type="radio" name="interpreter" value="no" />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="interpreter">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="interpreter"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-2 font-bold font20">
                Guardian Information:
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Full Name </label>
                    <Form.Item name="guardianFullName">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Relationship to Client </label>
                    <Form.Item name="guardianRelationship">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Phone </label>
                    <Form.Item name="guardianPhone">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Email </label>
                    <Form.Item
                      name="guardianEmail"
                      rules={[
                        {
                          type: "email",
                          message: "Please input a valid!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Full Name </label>
                    <Form.Item name="guardianfullName2">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Relationship to Client </label>
                    <Form.Item name="guardianRelationship2">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Phone </label>
                    <Form.Item name="guardianPhone2">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Email </label>
                    <Form.Item
                      name="guardianEmail2"
                      rules={[
                        {
                          type: "email",
                          message: "Please input a valid!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-2 font-bold font20">
                High Needs Case Manager
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Full Name </label>
                    <Form.Item name="managerFullName">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Agency </label>
                    <Form.Item name="managerAgency">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Phone </label>
                    <Form.Item name="managerPhone">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Email </label>
                    <Form.Item
                      name="managerEmail"
                      rules={[
                        {
                          type: "email",
                          message: "Please input a valid!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-2 font-bold font20">Therapist</div>
              <div className="row">
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Full Name </label>
                    <Form.Item name="therapistFullName">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Agency </label>
                    <Form.Item name="therapistAgency">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Phone </label>
                    <Form.Item name="therapistPhone">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Email </label>
                    <Form.Item
                      name="therapistEmail"
                      rules={[
                        {
                          type: "email",
                          message: "Please input a valid!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-2 font-bold font20">
                Emergency Contact:
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Full Name </label>
                    <Form.Item name="emergencyFullName">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Agency </label>
                    <Form.Item name="emergencyAgency">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label> Phone </label>
                    <Form.Item name="emergencyPhone">
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 my-2">
                  <div className="form-group">
                    <label>
                      {" "}
                      Is it okay to contact them during emergency?{" "}
                    </label>
                    <Form.Item name="emergencyApproval">
                      <select className="form-control">
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-2 font-bold font20">Referral </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Referring Source</label>
                    <Form.Item name="referringSource">
                      <TextArea rows="3" className="form-control"></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Referral Reason</label>
                    <Form.Item name="referralReason">
                      <TextArea rows="3" className="form-control"></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Presenting Issues</label>

                    <Form.Item name="presentingIssues">
                      <TextArea
                        rows="3"
                        className="form-control"
                        placeholder="(syptoms, duration,severity and contributing factor)"
                      ></TextArea>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-2 font-bold font20">
                Clinical Features:
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <div className="mt-4 mb-2 font-bold font18">
                      Suicidality
                    </div>
                    <label>Ideation</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Suicidality-Ideation">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Suicidality-Ideation"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Suicidality-Ideation">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Active
                            <input
                              type="radio"
                              name="Suicidality-Ideation"
                              value="active"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Suicidality-Ideation">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Passive
                            <input
                              type="radio"
                              name="Suicidality-Ideation"
                              value="passive"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <label>Plan</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Suicidality-Plan">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Suicidality-plan"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Suicidality-Plan">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="Suicidality-plan"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <label>Attempts</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Suicidality-attempts">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Suicidality-attempts"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Suicidality-attempts">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            One
                            <input
                              type="radio"
                              name="Suicidality-attempts"
                              value="one"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Suicidality-attempts">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            More than one
                            <input
                              type="radio"
                              name="Suicidality-attempts"
                              value="MoreThanOne"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <label>
                      Date of last attempt{" "}
                      <span className="font10">(If any)</span>
                    </label>
                    <Form.Item name="last_attempts_date">
                      <input type="date" className="form-control mb-4" />
                    </Form.Item>
                    <label>Lethality of attempt</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Lethality-attempts">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            N/A
                            <input
                              type="radio"
                              name="Lethality-attempts"
                              value="N/A"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Lethality-attempts">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            Low
                            <input
                              type="radio"
                              name="Lethality-attempts"
                              value="low"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Lethality-attempts">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Moderate
                            <input
                              type="radio"
                              name="Lethality-attempts"
                              value="moderate"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Lethality-attempts">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            High
                            <input
                              type="radio"
                              name="Lethality-attempts"
                              value="high"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <div className="mt-4 mb-2 font-bold font18">
                      Self Harm Behavior
                    </div>
                    <label>Current?</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Self-Harm-Behavior-Current">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Self-Harm-Behavior-Current"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Self-Harm-Behavior-Current">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="Self-Harm-Behavior-Current"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <label>Past?</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Self-Harm-Behavior-Past">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Self-Harm-Behavior-Past"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Self-Harm-Behavior-Past">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            One
                            <input
                              type="radio"
                              name="Self-Harm-Behavior-Past"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <div className="mt-4 mb-2 font-bold font18">
                      Aggressive Behavior
                    </div>
                    <label>Towards others</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Aggressive-Behavior-Others">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Aggressive-Behavior-Others"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Aggressive-Behavior-Others">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="Aggressive-Behavior-Others"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <label>Towards Property</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Aggressive-Behavior-Property">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Aggressive-Behavior-Property"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Aggressive-Behavior-Property">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="Aggressive-Behavior-Property"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Legal charges/Involvement</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="Legal-Charges">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="Legal-Charges"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="Legal-Charges">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="Legal-Charges"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>

                    <div>
                      <Form.Item name="Legal-Charges-description">
                        <TextArea
                          className="form-control"
                          rows="2"
                          placeholder="If yes, describe"
                        ></TextArea>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mt-auto my-2">
                  <div className="form-group">
                    <label>Family Issues</label>
                    <Form.Item name="Family-Issues">
                      <TextArea rows="2" className="form-control"></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mt-auto my-2">
                  <div className="form-group">
                    <label>Employment Issues</label>
                    <Form.Item name="Employment-Issues">
                      <TextArea rows="2" className="form-control"></TextArea>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Functional Concern</label>
                    <Form.Item name="Functional-Concern">
                      <TextArea
                        rows="4"
                        className="form-control"
                        placeholder="(Self Care/hygiene budgeting/finances, homemaking, eating/meal preparation, daily activities)"
                      ></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Referral/Waitlisted for other services</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="waitlisted-Other-Services">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            No
                            <input
                              type="radio"
                              name="waitlistedOtherServices"
                              value="no"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="waitlisted-Other-Services">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Yes
                            <input
                              type="radio"
                              name="waitlistedOtherServices"
                              value="yes"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <Form.Item name="other-care-providers">
                      <TextArea
                        rows="2"
                        className="form-control"
                        placeholder="List other involved care providers"
                      ></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Current Medication List</label>
                    <Form.Item name="Current-Medication-List">
                      <TextArea
                        rows="4"
                        className="form-control"
                        placeholder="medication name and dose"
                      ></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Allergies</label>
                    <Form.Item name="allergies">
                      <TextArea rows="2" className="form-control"></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Medical Issues</label>
                    <Form.Item name="medicalIssues">
                      <TextArea rows="2" className="form-control"></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Previous Psychiatric Issues</label>
                    <Form.Item name="PreviousPsychiatricIssues">
                      <TextArea
                        rows="2"
                        className="form-control"
                        placeholder="Presenting problem,dates. Where you hospitalized?"
                      ></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Substance use(alcohol & drug)</label>
                    <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                      <div>
                        <Form.Item name="substanceUse">
                          <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                            Current Use
                            <input
                              type="radio"
                              name="substanceUse"
                              value="current"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="substanceUse">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            Past Use
                            <input
                              type="radio"
                              name="substanceUse"
                              value="past"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item name="substanceUse">
                          <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                            N/A
                            <input
                              type="radio"
                              name="substanceUse"
                              value="past"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </Form.Item>
                      </div>
                    </div>
                    <Form.Item name="substanceType">
                      <TextArea
                        rows="2"
                        className="form-control"
                        placeholder="Type, quantity and frequency"
                      ></TextArea>
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-12 mt-auto ml-auto mb-3">
                  <Form.Item>
                    <button className="btn btn-red" type="submit">
                      Start Now
                    </button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <Footer />
    </div>
  );
};
