import React, { useEffect } from "react";
import { Navbar } from "../component/navbar";
import { Footer } from "../component/Footer";
import { useState } from "react";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EMAIL_URL } from "../assets/data/commonValues";

export const Apply = () => {
  const [form] = Form.useForm();
  const [payload1, setPayload1] = useState([]);
  const [payload2, setPayload2] = useState([]);
  const [payload3, setPayload3] = useState([]);
  const [payload4, setPayload4] = useState([]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  let [page, setPage] = useState(1);

  const nextPage = () => {
    setPage(page + 1);
  };
  const prevPage = () => {
    setPage(page - 1);
  };

  const onFinish = (values) => {
    let finalPayload = {};
    if (page === 1) {
      nextPage();
      setPayload1(values);
    } else if (page === 2) {
      nextPage();
      setPayload2(values);
    } else if (page === 3) {
      nextPage();
      setPayload3(values);
    } else if (page === 4) {
      setPayload4(values);
      finalPayload = { ...payload1, ...payload2, ...payload3, ...values };
      console.log(finalPayload);
      let string = "";
      Object.keys(finalPayload).forEach((x) => {
        let a = x.replace(/([A-Z])/g, " $1").trim();

        string +=
          capitalize(a).replace("-", "") + ": " + finalPayload[x] + "<br>";
      });

      // string = encodeURIComponent(string);

      // console.log(string, "APPLY");
      // return;
      Axios({
        method: "POST",
        url: EMAIL_URL,
        data: {
          to: "ADMIN",
          subject: "New Coach Application",
          content: string,
        },
      }).then((response) => {
        toast.success("Thank you, Your application has been submitted");
        form.resetFields();
      });
    }
  };

  const capitalize = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please check your form, fill in the required field");
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="start-wrapper hidden">
      <ToastContainer />
      <div className="start-header text-center">
        <Navbar />
        <div className="content container">
          <p className="mb-4 font-bold font50">Application Form</p>
        </div>
      </div>
      <div className="mb-5 container">
        <div className="form-wrapper">
          <div className="form-header font-bold font18 px-4 py-3">
            Please Enter the details below
          </div>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className=" card">
              {/* first step */}
              {page === 1 ? (
                <div>
                  <div className="my-3 font-bold font20">
                    Applicant Information:
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Full Name </label>
                        <Form.Item
                          name="Applicant-FullName"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2 ml-auto">
                      <div className="form-group">
                        <label>Date</label>
                        <Form.Item
                          name="Date-Of-Application"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label> Address </label>

                        <Form.Item
                          name="Applicant-Address"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea
                            rows="2"
                            placeholder="Street Address,Apartment/Unit."
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> City </label>
                        <Form.Item
                          name="Applicant-City"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> State </label>
                        <Form.Item
                          name="Applicant-State"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Zip Code </label>
                        <Form.Item
                          name="Applicant-ZipCode"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item
                          name="Applicant-Phone"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Email </label>
                        <Form.Item
                          name="Applicant-Email"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input placeholder="Email" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Date Available </label>
                        <Form.Item
                          name="Applicant-Available-Date"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Desired Hourly Rate or Annual Salary($) </label>
                        <Form.Item
                          name="Applicant-Desired-Salary"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label> Position Applying for </label>
                        <Form.Item
                          name="Applicant-Position"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 mb-2">
                      <div className="form-group">
                        <label>
                          Are you authorized for employment in the U.S for any
                          employer?
                        </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item
                              name="Applicant-Authorization"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                YES
                                <input
                                  type="radio"
                                  name="authorized"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="Applicant-Authorization"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                NO
                                <input
                                  type="radio"
                                  name="authorized"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 mb-2">
                      <div className="form-group">
                        <label>Desired Schedule</label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item
                              name="Applicant-Desired-Schedule"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                Full-Time
                                <input
                                  type="radio"
                                  name="schedule"
                                  value="full-time"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="Applicant-Desired-Schedule"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                Part-Time
                                <input
                                  type="radio"
                                  name="schedule"
                                  value="part-time"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Number of Hours Per Week</label>
                        <Form.Item
                          name="Hours-Weekly"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <p className="font-bold font20">
                    Indicate the hours you are available for work{" "}
                    <span className="font10">(specify with AM or PM)</span> :
                  </p>
                  <div className="row">
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Sunday </label>
                        <Form.Item
                          name="Hours-Available"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Monday </label>
                        <Form.Item
                          name="Hours-Monday"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Tuesday </label>
                        <Form.Item
                          name="Hours-Tuesday"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Wednesday </label>
                        <Form.Item
                          name="Hours-Wednesday"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Thursday </label>
                        <Form.Item
                          name="Hours-Thursday"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Friday </label>
                        <Form.Item
                          name="Hours-Friday"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> Saturday </label>
                        <Form.Item
                          name="Hours-Saturday"
                          rules={[
                            {
                              required: true,
                              message: "Please indicate!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="9am - 1pm"
                            className="form-control"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="text-right">
                    <div className="mb-3">
                      <button className="btn btn-red" type="submit">
                        Next 1/4
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {page === 2 ? (
                <div>
                  {/* second step */}
                  <div className="mt-4 mb-2 font-bold font20">EDUCATION</div>
                  <div className="row my-5">
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> School </label>
                        <Form.Item
                          name="School-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Address </label>
                        <Form.Item
                          name="School-Address-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Year Completed </label>
                        <Form.Item
                          name="SchoolYear-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Major/Degree Received </label>
                        <Form.Item
                          name="Schooldegree-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Did you Graduate? </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item
                              name="Graduated-1"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                Yes
                                <input
                                  type="radio"
                                  name="graduate"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="Graduated-1"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                No
                                <input
                                  type="radio"
                                  name="graduate"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5">
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> School </label>
                        <Form.Item name="School-2">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Address </label>
                        <Form.Item name="SchoolAddress-2">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Year Completed </label>
                        <Form.Item name="SchoolYear-2">
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Major/Degree Received </label>
                        <Form.Item name="SchoolDegree-2">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Did you Graduate? </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item name="Graduated-2">
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                Yes
                                <input
                                  type="radio"
                                  name="graduate2"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="Graduated-2">
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                No
                                <input
                                  type="radio"
                                  name="graduate2"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5">
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> School </label>
                        <Form.Item name="School-3">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Address </label>
                        <Form.Item name="SchoolAddress-3">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Year Completed </label>
                        <Form.Item name="SchoolYear-3">
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Major/Degree Received </label>
                        <Form.Item name="Schooldegree-3">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Did you Graduate? </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item name="Graduated-3">
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                Yes
                                <input
                                  type="radio"
                                  name="graduate3"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="Graduated-3">
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                No
                                <input
                                  type="radio"
                                  name="graduate3"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label> Licenses and/or Certifications </label>
                        <Form.Item
                          name="license"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="mb-3">
                      <button className="btn btn-red" onClick={prevPage}>
                        Previous
                      </button>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-red" type="submit">
                        Next 2/4
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {page === 3 ? (
                <div>
                  <div className="mt-4 mb-2 font-bold font20">
                    EMPLOYMENT HISTORY
                  </div>
                  <div className="row my-5">
                    <div className="col-lg-9 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Company</label>
                        <Form.Item
                          name="company-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item
                          name="companyPhone-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-9 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Address</label>
                        <Form.Item
                          name="companyAddress-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Supervisor </label>
                        <Form.Item
                          name="company-supervisor-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Title </label>
                        <Form.Item
                          name="company-title-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Hourly Rate/Salary($) </label>
                        <Form.Item
                          name="company-salary-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>Responsibilities</label>
                        <Form.Item
                          name="company-responsibility-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> From </label>
                        <Form.Item
                          name="companyFrom-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> To </label>
                        <Form.Item
                          name="companyTo-1"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Reason for Leaving </label>
                        <Form.Item
                          name="companyReasonForLiving-1"
                          rules={[
                            {
                              required: false,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 mb-2">
                      <div className="form-group">
                        <label>May we contact? </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item
                              name="companyContact-Permission-1"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                YES
                                <input
                                  type="radio"
                                  name="companyContact"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              name="companyContact-Permission-1"
                              rules={[
                                {
                                  required: true,
                                  message: "check one!",
                                },
                              ]}
                            >
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                NO
                                <input
                                  type="radio"
                                  name="companyContact"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> If no, why? </label>
                        <Form.Item name="company-noContactReason-1">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5">
                    <div className="col-lg-9 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Company</label>
                        <Form.Item name="company2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item name="companyPhone2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-9 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Address</label>
                        <Form.Item name="companyAddress2">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Supervisor </label>
                        <Form.Item name="company-supervisor2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Title </label>
                        <Form.Item name="company-title-2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Hourly Rate/Salary($) </label>
                        <Form.Item name="company-salary-2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>Responsibilities</label>
                        <Form.Item name="company-responsibility-2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> From </label>
                        <Form.Item name="companyFrom-2">
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> To </label>
                        <Form.Item name="companyTo-2">
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Reason for Leaving </label>
                        <Form.Item name="companyReasonForLiving-2">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 mb-2">
                      <div className="form-group">
                        <label>May we contact? </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item name="companyContact-Permission">
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                YES
                                <input
                                  type="radio"
                                  name="companyContact2"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="companyContact-Permission">
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                NO
                                <input
                                  type="radio"
                                  name="companyContact2"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> If no, why? </label>
                        <Form.Item name="company-noContactReason-2">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5">
                    <div className="col-lg-9 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Company</label>
                        <Form.Item name="company-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item name="companyPhone-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-9 col-sm-12 my-2">
                      <div className="form-group">
                        <label>Address</label>
                        <Form.Item name="companyAddress-3">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Supervisor </label>
                        <Form.Item name="company-supervisor-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Title </label>
                        <Form.Item name="company-title-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Hourly Rate/Salary($) </label>
                        <Form.Item name="comapny-salary-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>Responsibilities</label>
                        <Form.Item name="company-responsibility-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> From </label>
                        <Form.Item name="companyFrom-3">
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> To </label>
                        <Form.Item name="companyTo-3">
                          <input type="date" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Reason for Leaving </label>
                        <Form.Item name="companyReasonForLiving-3">
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-12 mb-2">
                      <div className="form-group">
                        <label>May we contact? </label>
                        <div className="d-flex text-black align-items-center font14 font-AftaSans mb-4">
                          <div>
                            <Form.Item name="companyContact-Permission-3">
                              <label className="radio-container mb-0 mr-4 font14 font-AftaSans">
                                YES
                                <input
                                  type="radio"
                                  name="companyContact3"
                                  value="yes"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item name="companyContact-Permission-3">
                              <label className="radio-container mr-4 mb-0 font14 font-AftaSans">
                                NO
                                <input
                                  type="radio"
                                  name="companyContact3"
                                  value="no"
                                />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-sm-12 mb-2">
                      <div className="form-group">
                        <label> If no, why? </label>
                        <Form.Item name="company-noContactReason-3">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-3">
                      <button className="btn btn-red" onClick={prevPage}>
                        Previous
                      </button>
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-red" type="submit">
                        Next 3/4
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {page === 4 ? (
                <div>
                  <div className="mt-4 mb-2 font-bold font20">
                    ADDITIONAL SKILLS
                  </div>
                  <div className="row">
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>
                          Please list any languages, other than English, you
                          speak and write fluently that relate to the position
                          you are applying for:
                        </label>
                        <Form.Item name="otherLanguages">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mb-2 font-bold font20">REFERENCES</div>
                  <p className="font-bold">
                    Please list three professional references that are familiar
                    with your work and employment history.
                  </p>
                  <div className="row my-5">
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Name </label>
                        <Form.Item
                          name="refereeName-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Years Known </label>
                        <Form.Item
                          name="referee-Known-Years-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> How You Know Them </label>
                        <Form.Item
                          name="referee-Known-How-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item
                          name="referee-Phone-1"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5">
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Name </label>
                        <Form.Item
                          name="refereeName-2"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Years Known </label>
                        <Form.Item
                          name="referee-Known-Years-2"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> How You Know Them </label>
                        <Form.Item
                          name="referee-Known-How-2"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item
                          name="referee-Phone-2"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row my-5">
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Name </label>
                        <Form.Item
                          name="refereeName-3"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Years Known </label>
                        <Form.Item
                          name="referee-Known-Years-3"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> How You Know Them </label>
                        <Form.Item
                          name="referee-Known-How-3"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 my-2">
                      <div className="form-group">
                        <label> Phone </label>
                        <Form.Item
                          name="referee-Phone-3"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          <Input className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 mb-2 font-bold font20">
                    ADDITIONAL INFORMATION
                  </div>
                  <div className="row">
                    <div className="col-12 my-2">
                      <div className="form-group">
                        <label>
                          {" "}
                          Have you ever resigned in lieu of being terminated? If
                          so, explain{" "}
                        </label>
                        <Form.Item name="everResigned">
                          <TextArea rows="2" className="form-control" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-3">
                      <button className="btn btn-red" onClick={prevPage}>
                        Previous
                      </button>
                    </div>
                    <Form.Item>
                      <div className="mb-3">
                        <button className="btn btn-red" type="submit">
                          Submit
                        </button>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              ) : null}
            </div>
          </Form>
        </div>
      </div>

      <Footer />
    </div>
  );
};
