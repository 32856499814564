import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';

export const Services = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  return (
    <div id="services">
      <section className="my-5 text-center container services-wrapper">
        <div className="row">
          <div className="col-lg-4 col-sm-12 my-3">
            <div>
              <span
                className="iconify my-2"
                data-inline="false"
                data-icon="cil:chat-bubble"
              ></span>
              <div className="font-bold font25 mb-3">Communicate your way</div>
              <div className="subtitle mb-5">
                <p>
                  BxCoach allows you to communicate via text, video, and group
                  video sessions with your guardian.
                </p>
              </div>
              <Link to="/contact">
                <button className="btn btn-red">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 my-3">
            <div>
              <span
                className="iconify my-2"
                data-inline="false"
                data-icon="ant-design:safety-certificate-twotone"
              ></span>
              <div className="font-bold font25 mb-3">Confidential Services</div>
              <div className="subtitle mb-5">
                <p>
                  BxCoach is Health Insurance Portability and Accountability Act
                  (HIPAA) compliant. All of your information is protected and
                  only shared if subpoenaed or issues requiring mandatory
                  reporting.
                </p>
              </div>
              <Link to="/contact">
                <button className="btn btn-red">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 my-3">
            <div>
              <span
                className="iconify my-2"
                data-inline="false"
                data-icon="ic:outline-support-agent"
              ></span>
              <div className="font-bold font25 mb-3">
                Get the Support Needed
              </div>
              <div className="subtitle mb-5">
                <p>
                  We give an extra safety feature to
                  ensure we respond swiftly when a child or young adult is
                  voicing danger to self or danger to others statements. We have
                  an added safety alert feature that alerts the emergency
                  contact of the statements made by the child and gives the
                  location.
                </p>
              </div>
              <Link to="/contact">
                <button className="btn btn-red">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
