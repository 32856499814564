import React from 'react';
import {Navbar} from '../component/navbar';
import {Link} from 'react-router-dom';
import {Footer} from '../component/Footer';
import {Services} from './services';
import girl from '../assets/img/bxcoach-card.png';
import {useEffect} from 'react';

export const Home = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="hidden">
      <div className="header">
        <Navbar />
        <section className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-12 my-5">
              <p className="font50 font-bold mt-5">
                Serving the needs of children & young adults.
              </p>
              <p>
                BxCoach is an innovative service that provides 24/7 care to
                assist children and young adults acquire the skills necessary to
                progress towards adulthood. We have designed a system that
                promotes safety and skill building in a way applicable to the
                needs of this generation.
              </p>
              <div className="button-div">
                <Link to="/start">
                  <button className="btn btn-red mt-3 btn-bg">
                    Complete Intake Form
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-lg-1 col-sm-12"></div>
            <div className="col-lg-6 col-sm-12 ">
              <img src={girl} alt="" className="girl-card" />
            </div>
          </div>
        </section>
      </div>
      <section className="text-center container ">
        <div className="plan-section">
          <div className="row">
            <div className="col-lg-6 col-sm-12 mx-auto">
              <div className="title">Let's make a plan </div>
              <p className="font20 font-light">
                Work with your BxCoach to develop a plan that identifies
                attainable goals you desire to achieve. This plan is about you,
                with input from your guardian.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="slider-wrapper">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item banner1 active"></div>
            <div className="carousel-item banner2"></div>
            <div className="carousel-item banner3"></div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div className="short-about">
          <p>
            BxCoach helps you find your voice, manage your emotions, and modify
            maladaptive behaviors that aren’t positively contributing to your
            life. Your BxCoach is with you wherever you go, whenever you need
            them; helping you develop age-appropriate skills necessary to
            progress towards independence
          </p>
          <div className="d-flex">
            <Link to="/about" className="mr-3">
              <button className="btn btn-white">About Us</button>
            </Link>
            <a href="#services">
              <button className="btn btn-red">Services</button>
            </a>
          </div>
        </div>
      </section>
      <Services />
      <section className="form-intake">
        <div className="container">
          <p className="font40 font-bold">
          Interested in becoming a BxCoach?
          </p>
          <Link to="/apply">
            <button className="btn btn-red mt-3 btn-bg">
              Apply
            </button>
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
};
