import React from 'react';
import logo from '../assets/img/logo.png';
import { NavLink, Link } from 'react-router-dom';

export const Navbar = () => {
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <a className="navbar-brand" href="#">
          <img src={logo} alt="" className="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ml-auto">
            <NavLink to="/" exact={true} activeClassName='active' className="nav-item nav-link">
              Home
            </NavLink>
            <NavLink to="/about" exact={true} activeClassName='active' className="nav-item nav-link">
              About Us
            </NavLink>
            <a href="/#services" className="nav-item nav-link">
              Services
            </a>
            <NavLink to="/start" exact={true} activeClassName='active'  className="nav-item nav-link">
              Getting Started
            </NavLink>
            <NavLink to="/contact" exact={true} activeClassName='active'  className="nav-item nav-link">
              Contact Us
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
};
