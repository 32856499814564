import React, { useEffect } from "react";
import { Navbar } from "../component/navbar";
import { Footer } from "../component/Footer";
import { Services } from "./services";
import woman from "../assets/img/bg1.jpg";

export const Privacy = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="privacy-wrapper hidden">
      <div className="privacy-header text-center">
        <Navbar />
        <div className="row">
          <div className="col-lg-6 col-sm-12 mx-auto my-5">
            <p className="mb-4 font-bold font50">Privacy Policy</p>
            <p className="font14">
              <strong>Privacy Policy</strong>{" "}
              <p>
                Green Consulting and Staffing built the BxCoach app for the
                purpose of providing an inexpensive service for those seeking
                assistance with helping their children, and young adults, learn
                skills necessary to progress towards adulthood. A plan can be
                selected within the App, and you will be prompted to provide
                payment for the service option you choose. There is also a
                contractual option with in App invoices that can be sent
                monthly. It is intended for use as is.
              </p>{" "}
              <p>
                This page is used to inform visitors regarding our policies with
                the collection, use, and disclosure of Personal Information if
                anyone decided to use our Service.
              </p>{" "}
              <p>
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </p>{" "}
              <p>
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which is accessible at BxCoach
                unless otherwise defined in this Privacy Policy.
              </p>{" "}
              <p>
                <strong>Information Collection and Use</strong>
              </p>{" "}
              <p>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to Name, Email, Location.
                The information that we request will be retained by us and used
                as described in this privacy policy.
              </p>{" "}
              <div>
                <p>
                  The app does use third party services that may collect
                  information used to identify you.
                </p>{" "}
                <p>
                  Link to privacy policy of third party service providers used
                  by the app
                </p>{" "}
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/policies/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play Services
                    </a>
                  </li>{" "}
                  <li>
                    <a
                      href="https://firebase.google.com/support/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Firebase Crashlytics
                    </a>
                  </li>{" "}
                  <li>
                    <a
                      href="https://onesignal.com/privacy_policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      One Signal
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://expo.io/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Expo
                    </a>
                  </li>{" "}
                </ul>
              </div>{" "}
              <p>
                <strong>Log Data</strong>
              </p>{" "}
              <p>
                We want to inform you that whenever you use our Service, in a
                case of an error in the app we collect data and information
                (through third party products) on your phone called Log Data.
                This Log Data may include information such as your device
                Internet Protocol (“IP”) address, device name, operating system
                version, the configuration of the app when utilizing our
                Service, the time and date of your use of the Service, and other
                statistics.
              </p>{" "}
              <p>
                <strong>Cookies</strong>
              </p>{" "}
              <p>
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory.
              </p>{" "}
              <p>
                This Service does not use these “cookies” explicitly. However,
                the app may use third party code and libraries that use
                “cookies” to collect information and improve their services. You
                have the option to either accept or refuse these cookies and
                know when a cookie is being sent to your device. If you choose
                to refuse our cookies, you may not be able to use some portions
                of this Service.
              </p>{" "}
              <p>
                <strong>Service Providers</strong>
              </p>{" "}
              <p>
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>{" "}
              <ul>
                <li>To facilitate our Service;</li>{" "}
                <li>To provide the Service on our behalf;</li>{" "}
                <li>To perform Service-related services; or</li>{" "}
                <li>To assist us in analyzing how our Service is used.</li>
              </ul>{" "}
              <p>
                We want to inform users of this Service that these third parties
                have access to your Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </p>{" "}
              <p>
                <strong>Security</strong>
              </p>{" "}
              <p>
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </p>{" "}
              <p>
                <strong>Links to Other Sites</strong>
              </p>{" "}
              <p>
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </p>{" "}
              <p>
                <strong>Changes to This Privacy Policy</strong>
              </p>{" "}
              <p>
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page.
              </p>{" "}
              <p>This policy is effective as of 2020-12-01</p>{" "}
              <p>
                <strong>Contact Us</strong>
              </p>{" "}
              <p>
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at
                BxCoach@tgreen.solutions.
              </p>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
