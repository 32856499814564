import React from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import { Home } from "./pages/home";
import { Start } from "./pages/start";
import { About } from "./pages/About";
import { Privacy } from "./pages/Privacy";
import { Apply } from "./pages/Apply";
import { Contact } from "./pages/Contact";
// import 'antd/dist/antd.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/start" component={Start} />
          <Route exact path="/about" component={About} />
          <Route exact path="/apply" component={Apply} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy" component={Privacy} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
