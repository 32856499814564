import React, { useEffect } from 'react';
import {Navbar} from '../component/navbar';
import { Footer } from '../component/Footer';
import { Services } from './services';
import woman from '../assets/img/bg1.jpg'

export const About = () => {
  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (
    <div className="about-wrapper hidden">
      <div className="about-header text-center">
        <Navbar />
        <div className="row">
          <div className="col-lg-6 col-sm-12 mx-auto my-5">
            <p className="mb-4 font-bold font50">About Us</p>
            <p className="font20">
              BxCoach is the first 24/7 Behavior Coaching Agency in the palm of
              your hand. Our Behavior Coaches help each child learn skills
              necessary to regulate their emotions, modify behaviors, and
              achieve their goals. Crisis Support is available 24/7 within the
              App to help reinforce skills learned during difficult times. Your
              Coach is with you wherever you go, whenever you need them; helping
              you develop skills necessary to progress towards independence.
            </p>
          </div>
        </div>
      </div>
      {/* <section className='mb-5 bg-curve'></section> */}
      {/* <section className="offices text-center">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mx-auto">
            <p className="mb-4 font-bold font50">Our Offices</p>
            <p className="font20">
            We have two convenient locations in Midtown and Pineville both located off of Park Road. Our space is casual and family-friendly. We want you to feel welcome and comfortable. Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores laudantium natus nesciunt nam ipsa quia quo culpa perspiciatis, rerum deserunt rem voluptate corporis, non adipisci fuga ipsam iusto illo? Cum!
            </p>
          </div>
        </div>
      </section>
      <section className='mb-5 bg-curve'></section> */}
      <section className='container my-5'>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <img src={woman} alt="" className='w-100'/>
        </div>
        <div className="col-lg-1 col-sm-12"></div>
        <div className="col-lg-5 col-sm-12 my-auto">
          <p className="font20 font-light">
            BxCoach has an internal GPS system that provides a location for each interaction. Our added safety alert feature alerts the emergency contact of the statements made by the child and gives the location.
          </p>
        </div>
      </div>
      </section>
      <Services/>
      <Footer/>
    </div>
  );
};
