import React from "react";
// import logo from "../assets/img/logo-white.jpg";
import logo from '../assets/img/logo.png';
import { Link } from "react-router-dom";
import google from "../assets/img/google-play.svg";

export const Footer = () => {
  return (
    <div>
      {/* <footer>
        <div className="container">
            <div className='row mb-5'>
                <div className="col-lg-1 col-4">
                    <img src={logo} alt="" className="w-100"/>
                </div>
            </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div>
                <p className='font-bold'>LOCATION</p>
                <p>10516 Park Road</p>
                <p>Charlotte, NC 28210</p>
                <p>Get Driving way</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div>
                <p className='font-bold'>CONTACT</p>
                <p><a href="mailto:bxcoach@tgreen.solution">bxcoach@tgreen.solution</a></p>
                <p><a href="tel:+11190200000">+11190200000</a></p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div>
                <p className='font-bold'>FOLLOW ALONG</p>
                <p className='d-flex'>
                    <a href="#" className='mr-3'><i className="fa fa-facebook"></i></a>
                    <a href="#" className='mr-3'><i className="fa fa-instagram"></i></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer> */}

      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-sm-12 mx-auto text-center">
              <img src={logo} alt="" className="logo" />
              <div className="my-4">
                <div className="d-flex justify-content-center">
                  <div className="social facebook">
                    <i className="fa fa-facebook"></i>
                  </div>
                  <div className="social twitter">
                    <i className="fa fa-twitter"></i>
                  </div>
                  <div className="social instagram">
                    <i className="fa fa-instagram"></i>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/#services">Services</Link>
                <Link to="/start">Get Started</Link>
              </div>
              <p>
                Copyright © 2020 BxCoach <br />
                Phoenix, Az <br/>
                <a href='mailto:bxcoach@tgreen.solutions'>bxcoach@tgreen.solutions</a>
                <br /> <br />
                <Link to="/privacy"> Privacy Policy </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="download">
          <div
            className="icon apple"
            data-toggle="tooltip"
            data-placement="right"
            title="Download on Playstore"
          >
            <img src={google} alt="" />
          </div>
          <div
            className="icon android"
            data-toggle="tooltip"
            data-placement="right"
            title="Download on Appstore"
          >
            <i className="fa fa-apple"></i>
          </div>
        </div>
      </footer>
    </div>
  );
};
