import React, { useEffect } from "react";
import { Navbar } from "../component/navbar";
import { Footer } from "../component/Footer";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EMAIL_URL } from "../assets/data/commonValues";

export const Contact = () => {
  const [form] = Form.useForm();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  const onFinish = (values) => {
    console.log(values);
    let string = "";
    Object.keys(values).forEach((x) => {
      string += x + ": " + values[x] + "\n";
    });

    Axios({
      method: "POST",
      url: EMAIL_URL,
      data: {
        to: "ADMIN",
        subject: "New Support Ticket",
        content: string,
      },
    }).then((response) => {
      toast.success("Ticket sent successfully, We'll get back to you soon.");
      form.resetFields();
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="start-wrapper">
      <ToastContainer />
      <div className=" hidden pb-5">
        <div className="start-header text-center">
          <Navbar />
          <div className="content container">
            <p className="mb-4 font-bold font50">Contact Us</p>
          </div>
        </div>
        <div className="mb-5 container">
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="form-wrapper"
          >
            <div className="form-header font-bold font18 px-4 py-3">
              Please fill the form below
            </div>
            <div className=" card">
              <div className="row">
                <div className="col-md-6 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Full Name</label>

                    <Form.Item
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your full name!",
                        },
                      ]}
                    >
                      <Input placeholder="Full name" className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 my-2">
                  <div className="form-group">
                    <label>Email Address </label>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input placeholder="Email" className="form-control" />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-12 my-2">
                  <div className="form-group">
                    <label>Message </label>

                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please type in your message!",
                        },
                      ]}
                    >
                      <TextArea
                        rows="3"
                        placeholder="Type your message here....."
                        className="form-control"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-md-3 ml-auto my-3">
                  <Form.Item>
                    <button type="submit" className="btn btn-red">
                      Submit
                    </button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
